import { UseInfiniteQueryResult, UseQueryResult } from "@tanstack/react-query";
import {
  useGetQuery,
  UseGetQueryOptions,
  useInfiniteGetQuery,
  UseInfiniteGetQueryOptions,
} from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { z } from "zod";

export const DEFAULT_PAGE_SIZE = 24;

export const WORKPLACE_WORKERS_ENDPOINT_URL = `${environmentConfig.REACT_APP_BASE_API_URL}/workplace/workers`;

export enum WorkerCategory {
  ALL_WORKERS = "all_workers",
  UPCOMING = "upcoming",
  IN_PROGRESS = "in_progress",
  PAST = "past",
  FAVORITE = "favorite",
}

const paginationLinksSchema = z.object({
  next: z.string().optional(),
  prev: z.string().optional(),
  nextCursor: z.string().optional(),
  prevCursor: z.string().optional(),
});

const includeEnum = z.enum(["ranking", "exclusion"]);
const fieldsSchema = z.object({
  exclusion: z
    .string()
    .refine((values) => values.split(",").every((item) => ["id"].includes(item)), {
      message: "Only 'id' field is allowed",
    })
    .optional(),
  ranking: z
    .string()
    .refine(
      (values) => values.split(",").every((item) => ["id", "rank", "isOnATeam"].includes(item)),
      {
        message: "Only 'id', 'rank', 'isOnATeam' field is allowed",
      }
    )
    .optional(),
});

/**
 * Describes the parameters for the /api/workplace/worker API.
 */
const workerDetailsAtWorkplaceRequestSchema = z.object({
  category: z.nativeEnum(WorkerCategory),
  search: z.string().optional(),
  facilityId: z.string(),
  pageSize: z.number(),
  pageToken: z.string().optional(),
  sort: z.enum(["rank"]).optional(),
  workerType: z.string().optional(),
  include: z
    .union([includeEnum, includeEnum.array()])
    .transform((value) => (typeof value === "string" ? [value] : value))
    .optional(),
  fields: fieldsSchema.optional(),
});

const shiftMetaSchema = z.object({
  start: z.string(),
  end: z.string(),
  shiftId: z.string(),
  state: z.nativeEnum(WorkerCategory),
});

const rankingSchema = z.object({
  id: z.string().optional(),
  rank: z.number().optional(),
  isOnATeam: z.boolean().optional(),
});

export type RankingType = z.infer<typeof rankingSchema>;

const exclusionSchema = z.object({
  id: z.string().optional(),
});

export type ExclusionType = z.infer<typeof exclusionSchema>;

/**
 * Describes the schema for the object that the /api/workplace/worker
 * API returns.
 */
const workerDetailsAtWorkplaceSchema = z.object({
  attendance: z.object({ completedShifts: z.number() }),
  id: z.string(),
  name: z.string(),
  userId: z.string(),
  profileImageUrl: z.string().optional(),
  profileImagefileStorageFileKey: z.string().nullish(),
  profileImagefileStorageRootFolder: z.string().nullish(),
  qualification: z.string().nullish(),
  matchingFacilityQualifications: z.array(z.string()),
  shifts: z.object({
    past: z.number(),
    inprogress: z.number(),
    upcoming: z.number(),
    lastShiftId: z.string().nullish(),
    lastShiftEnd: z.string().nullish(),
    lastShiftStart: z.string().nullish(),
    cancelled: z.number().nullish(),
    cancelledInLastMonth: z.number().nullish(),
    hasRecentCancellation: z.boolean().nullish(),
    nextShift: shiftMetaSchema.optional(),
    lastShift: shiftMetaSchema.optional(),
  }),
  ranking: rankingSchema.optional(),
  exclusion: exclusionSchema.optional(),
});

export type WorkerDetailsAtWorkplaceSchemaType = z.infer<typeof workerDetailsAtWorkplaceSchema>;

const workerDetailsAtWorkplaceResponseSchema = z.object({
  workers: z.array(workerDetailsAtWorkplaceSchema),
  links: paginationLinksSchema.optional(),
});

export type WorkerDetailsAtWorkplaceAPIResponseType = z.infer<
  typeof workerDetailsAtWorkplaceResponseSchema
>;
export type WorkerDetailsAtWorkplaceParams = z.input<typeof workerDetailsAtWorkplaceRequestSchema>;

export function useGetWorkerDetailsAtWorkplace(
  params: WorkerDetailsAtWorkplaceParams,
  options: UseGetQueryOptions<WorkerDetailsAtWorkplaceAPIResponseType> = {}
): UseQueryResult<WorkerDetailsAtWorkplaceAPIResponseType> {
  return useGetQuery({
    url: WORKPLACE_WORKERS_ENDPOINT_URL,
    queryParams: params,
    requestSchema: workerDetailsAtWorkplaceRequestSchema,
    responseSchema: workerDetailsAtWorkplaceResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_WORKER_DETAILS_AT_WORKPLACE_FAILRE,
    },
    ...options,
  });
}

export function useInfiniteGetWorkerDetailsAtWorkplace(
  params: WorkerDetailsAtWorkplaceParams,
  options: UseInfiniteGetQueryOptions<WorkerDetailsAtWorkplaceAPIResponseType> = {}
): UseInfiniteQueryResult<WorkerDetailsAtWorkplaceAPIResponseType> {
  return useInfiniteGetQuery({
    url: WORKPLACE_WORKERS_ENDPOINT_URL,
    queryParams: params,
    requestSchema: workerDetailsAtWorkplaceRequestSchema,
    responseSchema: workerDetailsAtWorkplaceResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_WORKER_DETAILS_AT_WORKPLACE_FAILRE,
    },
    getNextPageParam: (lastPage) => {
      return lastPage.links?.nextCursor ? { pageToken: lastPage.links.nextCursor } : undefined;
    },
    ...options,
  });
}
