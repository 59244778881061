import { z } from "zod";
import { qualificationSchema } from "../Qualifications/types";

const workplaceTypeAttributesSchema = z.object({
  name: z.string(),
  description: z.string(),
  enabled: z.boolean(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export const workplaceTypeQualificationsSchema = z.array(
  z.object({
    type: z.literal("qualification"),
    id: z.string(),
  })
);

const workplaceTypeRelationshipsSchema = z.object({
  qualifications: z.object({
    data: workplaceTypeQualificationsSchema,
  }),
});

const workplaceTypeSchema = z.object({
  type: z.literal("workplace-type"),
  id: z.string(),
  attributes: workplaceTypeAttributesSchema,
  relationships: workplaceTypeRelationshipsSchema,
});

export const workplaceTypeResponseSchema = z.object({
  data: workplaceTypeSchema,
  included: z.array(qualificationSchema),
});

export const workplaceTypesResponseSchema = z.object({
  data: z.array(workplaceTypeSchema),
  included: z.array(qualificationSchema),
});

export const createWorkplaceTypeAttributesSchema = workplaceTypeAttributesSchema.omit({
  createdAt: true,
  updatedAt: true,
});

export const createWorkplaceTypeRequestSchema = z.object({
  data: z.object({
    type: z.literal("workplace-type"),
    attributes: createWorkplaceTypeAttributesSchema,
    relationships: workplaceTypeRelationshipsSchema,
  }),
});

const updateWorkplaceTypeAttributesSchema = createWorkplaceTypeAttributesSchema;

export const updateWorkplaceTypeRequestSchema = z.object({
  data: z.object({
    id: z.string(),
    type: z.literal("workplace-type"),
    attributes: updateWorkplaceTypeAttributesSchema,
    relationships: workplaceTypeRelationshipsSchema,
  }),
});

export type WorkplaceType = z.infer<typeof workplaceTypeSchema>;
export type WorkplaceTypeResponse = z.infer<typeof workplaceTypeResponseSchema>;
export type WorkplaceTypesResponse = z.infer<typeof workplaceTypesResponseSchema>;

export type CreateWorkplaceTypeAttributes = z.infer<typeof createWorkplaceTypeAttributesSchema>;
export type CreateWorkplaceTypeParams = CreateWorkplaceTypeAttributes & {
  qualifications: Array<{ id: string }>;
};

export type UpdateWorkplaceTypeAttributes = z.infer<typeof updateWorkplaceTypeAttributesSchema>;
export type UpdateWorkplaceTypeParams = UpdateWorkplaceTypeAttributes & {
  id: string;
  qualifications: Array<{ id: string }>;
};
