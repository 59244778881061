import { CbhFeatureFlag } from "@src/appV2/FeatureFlags";
import { APP_V2_APP_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { routes } from "@src/utils/routes";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { matchPath } from "react-router-dom";
import { usePrevious } from "react-use";
import { SessionType } from "src/modules/interface";

// Add any pages that have bugs related to changing the user.
// Such bugs can happen from having bad useEffect setups such that the content doesn't
// update to reflect the data of the new user, which can be quite dangerous
const UNSAFE_PAGE_ROUTES = [
  routes.workplaceWorkerDetails, // https://linear.app/clipboardhealth/issue/TF-936/workplace-worker-details-page-doesnt-react-properly-to-change-of
  routes.workersDirectoryWorkerDetails, // https://linear.app/clipboardhealth/issue/TF-937/workers-directory-worker-details-page-doesnt-react-properly-to-change
  routes.facilityWorkerInstructions, // https://linear.app/clipboardhealth/issue/TF-939/facility-information-page-doesnt-react-properly-to-change-of-facility
  routes.facilitySettings, // https://linear.app/clipboardhealth/issue/TF-940/facility-settings-page-doesnt-react-properly-to-change-of-facility
  routes.facilityInvoiceContractTerms, // https://linear.app/clipboardhealth/issue/TF-941/contract-terms-page-doesnt-react-properly-to-change-of-facility
];

const useReloadPageOnUserSwitch = () => {
  const { userId, adminUserId, admin, user } = useSelector((state: SessionType) => state.session);
  const previousUserId = usePrevious(userId);

  const userType = !admin && user ? "WORKPLACE_USER" : "CBH_EMPLOYEE";

  const ldClient = useLDClient();

  useEffect(() => {
    if (!ldClient) {
      return;
    }

    const isEnabled = ldClient?.variation(CbhFeatureFlag.RELOAD_PAGE_ON_USER_SWITCH, true);
    if (isEnabled !== true) {
      return;
    }

    // avoids an infinite reload loop
    if (!previousUserId) {
      return;
    }

    const currentPageIsSafe = !UNSAFE_PAGE_ROUTES.some(
      (unsafePageRoute) =>
        !!matchPath(window.location.pathname, { path: unsafePageRoute, exact: true })
    );

    if (previousUserId !== userId && !currentPageIsSafe) {
      logEvent(APP_V2_APP_EVENTS.USER_SWITCH_RELOAD, {
        previousUserId,
        userId,
        adminUserId,
        userType,
      });
      window.location.reload();
    }
  }, [adminUserId, ldClient, previousUserId, userId, userType]);
};

export { useReloadPageOnUserSwitch };
