import { deepPurple } from "@mui/material/colors";

export const GRAY_00 = "#000";
export const GRAY_4F = "#4f4f4f";
export const GRAY_82 = "#828282";
export const GRAY_C0 = "#c0c0c0";
export const GRAY_E0 = "#e0e0e0";
export const GRAY_E5 = "#e5e2e5";
export const GRAY_F0 = "#f0f0f0";
export const GRAY_F5 = "#f5f5f5";
export const GRAY_1F = "#1f2937";
export const GRAY_6B = "#6B7280";

export const PRIMARY_COLOR = "#032e68";
export const ERROR_COLOR = "#f00";

export const ALERT_COLORS = {
  warning: {
    backgroundColor: "#FFF4E5",
    color: "#663C00",
  },
  info: {
    backgroundColor: "#E5F6FD",
    color: "#014361",
  },
  error: {
    backgroundColor: "#FFF1F0",
    color: ERROR_COLOR,
  },
  success: {
    backgroundColor: "#D2F6D6",
  },
};

/** @deprecated for use in legacy pages where we want the styling to be consistent with old AntD components */
export const ANT_D_PRIMARY_COLOR = "#1890ff";

export const CHIP_COLORS = {
  primary: {
    color: "#0072bc",
    backgroundColor: "#e3f2fd",
  },
  secondary: { color: "#343A40", backgroundColor: "#ced4da" },
  success: { color: "#388e3c", backgroundColor: "#e4f8eb" },
  error: { color: "#d32f2f", backgroundColor: "#f5c2c7" },
  warning: { color: "#c45700", backgroundColor: "#f7e5cb" },
  info: { color: "#0288d1", backgroundColor: "#cbe9f5" },
  accent: { backgroundColor: deepPurple[100], color: deepPurple[800] },
};

export const TYPOGRAPHY_COLORS = {
  body2: {
    light: GRAY_4F,
    dark: GRAY_00,
  },
};

export const SECONDARY_COLORS = {
  /**
   * TODO - identify colors
   */
  light: "#0086e6",
  dark: "#00d0ff",
};

export const LINK_BLUE = "#0068b3";
export const AZUL_BLUE = "#2563eb";
export const EMBER_ORANGE = "#C45700";
