import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { Status } from "@src/appV2/FeatureFlags/CbhFeatureFlags";

export function useGetATeamConfig() {
  const aTeamConfig = useCbhFlag(CbhFeatureFlag.A_TEAM_CONFIG, {
    defaultValue: { status: Status.OFF },
  });
  const isATeamEnabled = aTeamConfig.status === Status.ON;
  return { isATeamEnabled };
}
