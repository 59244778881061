import { useState } from "react";
import { Stack, Step, StepLabel, Stepper } from "@mui/material";
import { type ShiftInviteDialogProps } from "../Dialogs";
import { isDefined } from "@clipboard-health/util-ts";
import { ShiftInviteNearbyWorkers } from "../Autocomplete";
import { NearbyWorkerSearchResult } from "../Autocomplete/useGetNearbyWorkers";
import { ShiftInviteReview } from "../ShiftInviteReview";
import { GroupShiftInviteDetails as DeprecatedGroupShiftInviteDetails } from "../DeprecatedGroupShiftInviteDetail";
import { GroupShiftInviteDetails } from "../GroupShiftInviteDetail";
import { ShiftInviteDetails } from "../ShiftInviteDetails";
import { useShiftInviteContext } from "../Context/shiftInviteContext";
import { ShiftName } from "@src/interface";
import { utcToZonedTime } from "date-fns-tz";
import { isAfter, startOfDay } from "date-fns";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";

export function ShiftInviteStepper(props: ShiftInviteDialogProps) {
  const { workerType, modalState, initialActiveState = 0, initialSelectedWorker } = props;
  const [activeStep, setActiveStep] = useState(initialActiveState);
  const [selectedWorker, setSelectedWorker] = useState<NearbyWorkerSearchResult | undefined>(
    initialSelectedWorker
  );
  const { isMultiShiftInvitesEnabled, validateAndUpdateShiftInvite, facilityTimeZone } =
    useShiftInviteContext();
  const useRevampedMultiShiftInviteUi = useCbhFlag(
    CbhFeatureFlag.USE_MULTI_SHIFT_INVITE_UI_REVAMP,
    { defaultValue: false }
  );
  const zoneShiftStartTime = utcToZonedTime(props.shiftInfo.start, facilityTimeZone);
  const nowInFacilityTimeZone = utcToZonedTime(new Date(), facilityTimeZone);
  const selectedShiftStartsInFuture =
    isDefined(zoneShiftStartTime) && isAfter(zoneShiftStartTime, nowInFacilityTimeZone);

  return (
    <Stack spacing={2}>
      <Stepper activeStep={activeStep}>
        <Step>
          <StepLabel>
            {isDefined(selectedWorker)
              ? `Inviting ${selectedWorker.fullName}, ${workerType}`
              : "Select a Professional"}
          </StepLabel>
        </Step>
        <Step>
          <StepLabel>
            {useRevampedMultiShiftInviteUi ? "Select Shift(s)" : "Confirm Shift Details"}
          </StepLabel>
        </Step>
        {isMultiShiftInvitesEnabled && (
          <Step>
            <StepLabel>Review Invites</StepLabel>
          </Step>
        )}
      </Stepper>
      {(activeStep === 0 || !isDefined(selectedWorker)) && (
        <ShiftInviteNearbyWorkers
          selectedWorker={selectedWorker}
          setSelectedWorker={setSelectedWorker}
          workerType={workerType}
          setActiveStep={setActiveStep}
          modalState={modalState}
          onClickNext={() => {
            if (isMultiShiftInvitesEnabled && selectedShiftStartsInFuture) {
              validateAndUpdateShiftInvite({
                selectedShiftInfo: {
                  start: zoneShiftStartTime,
                  end: utcToZonedTime(props.shiftInfo.end, facilityTimeZone),
                  shiftType: props.shiftInfo.shiftType as ShiftName,
                  isSelected: true,
                },
                selectedDate: startOfDay(zoneShiftStartTime),
                selectedWorker,
              });
            }
          }}
        ></ShiftInviteNearbyWorkers>
      )}
      {activeStep === 1 &&
        (isMultiShiftInvitesEnabled ? (
          useRevampedMultiShiftInviteUi ? (
            <GroupShiftInviteDetails
              selectedWorker={selectedWorker}
              setSelectedWorker={setSelectedWorker}
              setActiveStep={setActiveStep}
              shiftInviteDialogProps={props}
            />
          ) : (
            <DeprecatedGroupShiftInviteDetails
              selectedWorker={selectedWorker}
              setSelectedWorker={setSelectedWorker}
              setActiveStep={setActiveStep}
              shiftInviteDialogProps={props}
            />
          )
        ) : (
          <ShiftInviteDetails
            shiftInviteDialogProps={props}
            setActiveStep={setActiveStep}
            setSelectedWorker={setSelectedWorker}
            selectedWorker={selectedWorker}
          />
        ))}
      {isMultiShiftInvitesEnabled && activeStep === 2 && (
        <ShiftInviteReview
          modalState={modalState}
          workerType={workerType}
          setActiveStep={setActiveStep}
          selectedWorker={selectedWorker}
        ></ShiftInviteReview>
      )}
    </Stack>
  );
}
