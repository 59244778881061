import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { z } from "zod";
import { Resources } from "../types/resources";
import { DocumentType, DownloadRequestStatus } from "./../types/common";
import { get } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";

export const documentDownloadStatusResponseSchema = z.object({
  data: z.object({
    attributes: z.object({
      workerUserId: z.string(),
      workplaceId: z.string(),
      documentList: z.array(
        z.object({
          documentId: z.string(),
          documentType: z.nativeEnum(DocumentType),
        })
      ),
      erroredDocumentList: z
        .array(
          z.object({
            documentId: z.string(),
            documentType: z.nativeEnum(DocumentType),
          })
        )
        .optional(),
      status: z.nativeEnum(DownloadRequestStatus),
      requestedBy: z.string(),
      requestedByType: z.string(),
      processedAt: z.string().optional(),
      createdAt: z.string().optional(),
      updatedAt: z.string().optional(),
      file: z
        .object({
          url: z.string(),
          expiresAt: z.string().optional(),
        })
        .optional(),
    }),
    id: z.string(),
    type: z.literal(Resources.DOCUMENT_DOWNLOAD),
  }),
});

export type DocumentDownloadStatusResponse = z.infer<typeof documentDownloadStatusResponseSchema>;

interface GetDocumentDownloadStatusParams {
  documentDownloadId: string;
}

export function useGetDocumentDownloadStatus(
  options: UseMutationOptions<
    DocumentDownloadStatusResponse,
    unknown,
    GetDocumentDownloadStatusParams
  > = {}
) {
  return useMutation({
    mutationFn: async ({ documentDownloadId }) => {
      const response = await get<undefined, DocumentDownloadStatusResponse>({
        url: `${environmentConfig.REACT_APP_DOCUMENTS_SERVICE_REST_API_URL}/document-downloads/${documentDownloadId}`,
        responseSchema: documentDownloadStatusResponseSchema,
      });
      return response.data;
    },
    ...options,
  });
}
