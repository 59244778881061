import { useQuery } from "@tanstack/react-query";
import { getAuthHeader } from "src/utils/superagent";
import request from "superagent";
import { environmentConfig } from "@src/appV2/environment";

const apiMainUrl = environmentConfig.REACT_APP_API_MAIN_URL;

export enum WorkerStatusLabel {
  Favorite = "Favorite",
  Restricted = "Restricted",
  Blocked = "Blocked",
}

export interface NearbyWorkerSearchResult {
  workerId: string;
  phoneNumber: string;
  fullName: string;
  statusLabel?: WorkerStatusLabel;
}

export interface GetNearbyWorkersParams {
  workplaceId: string;
  searchKey: string;
  workerType: string;
}

export function useGetNearbyWorkers(getNearbyWorkersParams: GetNearbyWorkersParams) {
  const { workplaceId, searchKey, workerType } = getNearbyWorkersParams;
  const getNearbyWorkerUrl = `${apiMainUrl}/api/workplaces/${workplaceId}/nearby-workers`;
  return useQuery({
    queryKey: [getNearbyWorkerUrl, searchKey, workerType],
    queryFn: async () => {
      const authHeader = await getAuthHeader();

      const response = await request
        .get(getNearbyWorkerUrl)
        .set(authHeader)
        .query({ filter: { name: searchKey, qualification: workerType, excludeDNR: false } });

      return response.body.data.map((record: { attributes: NearbyWorkerSearchResult }) => ({
        ...record.attributes,
      }));
    },
    enabled: Boolean(searchKey),
  });
}
