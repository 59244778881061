import { Stack } from "@mui/material";
import { ExternalLink, Text } from "@clipboard-health/ui-react";
import { SUPPORT_LINKS } from "./constants";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";

interface ShiftInviteCustomRateNoAccessBannerProps {
  workplaceId: string;
  workplaceUserId: string;
}

export function ShiftInviteCustomRateNoAccessBanner(
  props: ShiftInviteCustomRateNoAccessBannerProps
) {
  return (
    <Stack spacing={0.5}>
      <Text bold>Custom Rates - Increase hourly rates per invite</Text>

      <Text variant="subtitle2" color={(theme) => theme.palette.text.secondary}>
        You are unable to adjust rates for shift invites due to the permission settings at your
        facility. Please reach out to your administrator or corporate contact to discuss{" "}
        <ExternalLink
          to={SUPPORT_LINKS.SHIFT_INVITE_CUSTOM_RATE}
          underline="hover"
          onClick={() => {
            logEvent(APP_V2_USER_EVENTS.SHIFT_INVITE_CUSTOM_RATE_SUPPORT_LINK_CLICKED, {
              ...props,
              createdAt: new Date(),
            });
          }}
        >
          access
        </ExternalLink>{" "}
        to this feature.
      </Text>
    </Stack>
  );
}
