import {
  useInfiniteQuery,
  type UseInfiniteQueryOptions,
  type UseInfiniteQueryResult,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";

import { type ApiResponse, get, type CommonApiParams } from "./api";
import { type ReactQueryMeta } from "./types";

type UseInfiniteQueryResponseType<ResponseSchema> = ApiResponse<ResponseSchema>["data"];

type LocallyDefinedUseInfiniteQueryOptions = "meta" | "queryKey" | "queryFn" | "getNextPageParam";
type DisallowedUseInfiniteQueryOptions = "onSuccess" | "onError" | "onSettled";

export type UseInfiniteGetQueryOptions<
  ResponseSchema,
  SelectData = UseInfiniteQueryResponseType<ResponseSchema>
> = Omit<
  UseInfiniteQueryOptions<
    UseInfiniteQueryResponseType<ResponseSchema>,
    AxiosError,
    SelectData,
    UseInfiniteQueryResponseType<ResponseSchema>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any
  >,
  LocallyDefinedUseInfiniteQueryOptions | DisallowedUseInfiniteQueryOptions
>;

export type UseInfiniteGetQueryProps<
  RequestSchema,
  ResponseSchema,
  SelectSchema = ResponseSchema
> = CommonApiParams<RequestSchema, ResponseSchema> &
  UseInfiniteGetQueryOptions<ResponseSchema, SelectSchema> & {
    meta: ReactQueryMeta;
    getNextPageParam: (
      lastPage: UseInfiniteQueryResponseType<ResponseSchema>,
      pages: UseInfiniteQueryResponseType<ResponseSchema>[]
    ) => unknown | undefined;
  };

export function useInfiniteGetQuery<RequestSchema, ResponseSchema, SelectSchema = ResponseSchema>(
  props: UseInfiniteGetQueryProps<RequestSchema, ResponseSchema, SelectSchema>
): UseInfiniteQueryResult<SelectSchema> {
  const { url, queryParams, requestSchema, responseSchema, getNextPageParam, ...options } = props;
  return useInfiniteQuery({
    ...options,
    queryKey: [url, queryParams],
    queryFn: async (parameters) => {
      const { pageParam } = parameters;
      const response = await get({
        url,
        queryParams: { ...queryParams, ...pageParam },
        requestSchema,
        responseSchema,
      });
      return response.data;
    },
    getNextPageParam,
  });
}
