import { z } from "zod";
import { Address } from "../lib/GoogleMaps";

export enum PlacementCandidateStatus {
  DISABLED = "disabled",
  ONBOARDING = "onboarding",
  PENDING = "pending",
  ACTIVATED = "activated",
}

export enum JobType {
  FULL_TIME = "full_time",
  PART_TIME = "part_time",
  PER_DIEM = "per_diem",
}

export enum ShiftNameType {
  AM = "am",
  PM = "pm",
  NOC = "noc",
}

export const placementGeoLocation = z.object({
  coordinates: z.tuple([z.number(), z.number()]),
  type: z.literal("Point"),
});

export type PlacementGeoLocation = z.infer<typeof placementGeoLocation>;

export interface PreferredCandidateArea {
  location: PlacementGeoLocation;
  address: Address;
  distance: number;
}

export type PlacementCandidate = {
  id: string;
  status: PlacementCandidateStatus;
  introductionUrl?: string;
  resumeUrl?: string;
  distance?: number;
  jobTypesInterested?: JobType[];
  shiftTypesInterested?: ShiftNameType[];
  preferredAreas?: PreferredCandidateArea[];
  worker: {
    id: string;
    name: string;
    qualification: string;
    completedShiftsCount?: number;
    cbhPassportDocuments?: string[];
    email?: string;
    phone?: string;
    profileImageUrl?: string;
  };
};
